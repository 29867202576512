<!--
 * @Author: your name
 * @Date: 2022-01-10 19:38:10
 * @LastEditTime: 2022-03-24 17:00:53
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /weichao_pc/src/views/member/login/Agreement.vue
-->
<template>
    <div class="sld_login">
        <div class="sld_login_header">
            <div class="content">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img src="../../../assets/common_top_logo.png" class="img" />
                </router-link>
                <div class="r_register_wrap">
                    {{L['我已知悉？']}}
                    <a class="go_register_btn" @click="goRegister">
                        {{L['去注册']}}
                    </a>
                </div>
            </div>
        </div>
        <div class="agreement_container">
            <h2 class="agreement_title">{{agreeContent.title}}</h2>
            <div class="goods_htmls" v-html="agreeContent.content"></div>
            <!-- <pre class="agreement_content" v-html="agreeContent.content"></pre> -->
        </div>
    </div>
</template>

<script>
    import { useRouter } from 'vue-router'
    import { getCurrentInstance, reactive, onMounted } from 'vue';

    export default {
        name: "Agreement",
        setup() {
            const router = useRouter()
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const agreeContent = reactive({
                content: '',
                title: ''
            })
            const agreementCode = reactive({
                agreementCode: 'register_agreement'
            })
            const getInitData = () => {
                proxy.$get('/v3/system/front/agreement/detail', agreementCode).then(res => {
                    if (res.state == 200) {
                        agreeContent.content = quillEscapeToHtml(res.data.content)
                        agreeContent.title = res.data.title
                    }
                })
            }
                 /*
 * 富文本内容反转义（接口返回的富文本内容经过了转义，导致内容无法展示，所以需要反转义）
 * @param {String} str 富文本内容
 * */
  function quillEscapeToHtml(str) {
  if(str!=undefined&&str){
    const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' }
    return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) {
      return arrEntities[t]
    })
  }else{
    return ''
  }
}
            const goRegister = () => {
                // router.push({
                //     path: '/register'
                // })
                router.back();
            }
            onMounted(() => {
                getInitData()
            })

            return { L, agreeContent, goRegister }
        },
    };
</script>
<style lang="scss" scoped>
    @import '../../../style/agreement.scss';
    .agreement_container{
        width: 800px;
        
    }
    .agreement_content{
        font-size: 15px;
        line-height: 35px;
        white-space:normal;
        word-break:break-all;
    }
    
</style>
